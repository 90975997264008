.home-headline {
  margin-bottom: 0;
  font-family: 'Open Sans';
  margin-top: 1em;
  font-weight: initial;
  margin-bottom: .5em;
  text-align: center;
}

.home-subtext {
  font-size: 20px; 
}

.home-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1em;
}

.home-text {
  margin: 0 2em;
  margin-bottom: 1em;
  max-width: 500px;
  text-align: center;
}


.home-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home-button {
  width: 225px;
  font-size: 24px;
  border-radius: 4px;
  border: none;

  font-family: var(--font-primary);
  border: 4px solid var(--spot-primary);
  background-color: transparent;
  color: var(--text-primary);
  cursor: pointer;
  margin: .5em;
  padding: .5em .5em;
  transition: .3s all;
  font-weight: bold;
}

.home-button:hover {
  background-color: var(--spot-primary);
  color: black;
}


.home-button.secondary {
  background-color: var(--bg-secondary);
  color: var(--spot-primary);
  border: 4px solid var(--bg-secondary);
}

.home-button.secondary:hover {
  border: 4px solid var(--spot-primary);
  background-color: var(--spot-primary);
  color: var(--text-primary);
}
