.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em; 
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 325px;
  background-color: var(--bg-secondary);
  padding: 1em;
  border-radius: 4px;
  color: var(--text-secondary);
  margin: 0 1em;
}

.contact-form label {
  font-size: 20px
}

.contact-form input {
  margin-bottom: 2em;
  border: none;
  border-bottom: 2px solid var(--spot-primary);
  height: 30px;
  font-size: 16px;
  padding: 0 4px;
  border-radius: 4px;
}

.contact-form textarea {
  height: 100px;
  resize: none;
  border-radius: 4px;
  border-style: none;
  font-size: 16px;
  padding: 4px;
}

.contact-form button {
  margin-top: 1em;
  width: 100px;
  height: 50px;
  font-size: 24px;
  margin-left: auto;
  border: none;
  border: 2px solid var(--spot-primary);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-secondary);
  transition: .3s all;
}

.contact-form button:hover {
  background-color: var(--spot-primary);
  color: black;
}

.contact-form .status {
  color: var(--text-secondary);
}

.contact-form .errorMsg {
  color: #b00000;
}