@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Lato');

html, body {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1;

  --bg-primary: #f6ffff;
  --bg-secondary: #293241;
  --text-primary: #10002b;
  --text-secondary: #f3ffff;
  --showcase-primary: #e1ffff;
  --spot-primary: #ee6c4d;
  --spot-secondary: #00c382;
  --font-primary: 'Open Sans';
  --font-secondary: 'Lato';

  font-family: var(--font-primary);
  overflow: auto;
  background-color: var(--bg-primary);
}

#root {
  height: 100%;
  width: 100%;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.app-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: auto;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 0 1em;
}

.page-title h2 {
  margin-bottom: 0em;
}

.page-title p {
  margin-top: .25em;
}