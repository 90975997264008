@import url(https://fonts.googleapis.com/css2?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Lato);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;

  --bg-primary: #f6ffff;
  --bg-secondary: #293241;
  --text-primary: #10002b;
  --text-secondary: #f3ffff;
  --showcase-primary: #e1ffff;
  --spot-primary: #ee6c4d;
  --spot-secondary: #00c382;
  --font-primary: 'Open Sans';
  --font-secondary: 'Lato';

  font-family: var(--font-primary);
  overflow: auto;
  background-color: var(--bg-primary);
}

#root {
  height: 100%;
  width: 100%;
}


.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.app-body {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-bottom: auto;
}

.page-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.page-title {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding: 0 1em;
}

.page-title h2 {
  margin-bottom: 0em;
}

.page-title p {
  margin-top: .25em;
}
.nav-logo {
  padding: 0px;
  font-size: 24px;
  display: -webkit-flex;
  display: flex;
  padding: 8px;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  color: #14213D;
  font-family: var(--font-primary);
  margin-right: auto;
  outline: none;
}

.nav-logo:hover {
  cursor: pointer; 
}

#logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 8px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.nav-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: .25em .25em;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.nav-link {
  font-size: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  border-bottom: 2px solid var(--spot-primary);
  margin: .25em .1em;
  background-color: var(--bg-secondary);
  cursor: pointer;
  color: var(--text-secondary);
  font-family: var(--font-primary);
  transition: .3s all;
}

.nav-link:hover {
  background-color: var(--spot-primary);
  color: black;
}
.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Open Sans';
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: .5em;
}
.home-headline {
  margin-bottom: 0;
  font-family: 'Open Sans';
  margin-top: 1em;
  font-weight: normal;
  font-weight: initial;
  margin-bottom: .5em;
  text-align: center;
}

.home-subtext {
  font-size: 20px; 
}

.home-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 1em;
}

.home-text {
  margin: 0 2em;
  margin-bottom: 1em;
  max-width: 500px;
  text-align: center;
}


.home-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.home-button {
  width: 225px;
  font-size: 24px;
  border-radius: 4px;
  border: none;

  font-family: var(--font-primary);
  border: 4px solid var(--spot-primary);
  background-color: transparent;
  color: var(--text-primary);
  cursor: pointer;
  margin: .5em;
  padding: .5em .5em;
  transition: .3s all;
  font-weight: bold;
}

.home-button:hover {
  background-color: var(--spot-primary);
  color: black;
}


.home-button.secondary {
  background-color: var(--bg-secondary);
  color: var(--spot-primary);
  border: 4px solid var(--bg-secondary);
}

.home-button.secondary:hover {
  border: 4px solid var(--spot-primary);
  background-color: var(--spot-primary);
  color: var(--text-primary);
}

.showcase-box {
  --showcase-border: 2px solid gray;
  max-width: 375px;
  margin: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--showcase-primary);
  border: var(--showcase-border);
  padding: 1em;

}

.showcase-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0px;
}

.showcase-header {
  margin-top: .5em;
  margin-bottom: 1em;
}

.showcase-header h2 {
  text-align: center;
  margin: 0;
}

.showcase-roles {
  margin: .5em 0;
}

.showcase-role-title {
  font-weight: bold;
  font-size: 16px;
  margin-right: .5em;
}

.showcase-roles {
  text-align: center;
}

.showcase-description {
  margin: 1em;
  margin-top: 0;
}

.showcase-description p {
  margin: 0;
}

.showcase-image {
    width: calc(100%); 
    border-radius: 4px;
    margin-bottom: 1em;
}

.action-button {
  border: none;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border-bottom: 2px solid var(--spot-primary);
  border-radius: 4px;
  font-size: 16px;
  padding: .5em 1em;
  margin: 0 .5em;
  cursor: pointer;
  font-weight: bold;
  transition: .3s all;
}

.action-button:hover {
  background-color: var(--spot-primary);
  color: black;
}

.showcase-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.contact {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1em; 
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 325px;
  background-color: var(--bg-secondary);
  padding: 1em;
  border-radius: 4px;
  color: var(--text-secondary);
  margin: 0 1em;
}

.contact-form label {
  font-size: 20px
}

.contact-form input {
  margin-bottom: 2em;
  border: none;
  border-bottom: 2px solid var(--spot-primary);
  height: 30px;
  font-size: 16px;
  padding: 0 4px;
  border-radius: 4px;
}

.contact-form textarea {
  height: 100px;
  resize: none;
  border-radius: 4px;
  border-style: none;
  font-size: 16px;
  padding: 4px;
}

.contact-form button {
  margin-top: 1em;
  width: 100px;
  height: 50px;
  font-size: 24px;
  margin-left: auto;
  border: none;
  border: 2px solid var(--spot-primary);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-secondary);
  transition: .3s all;
}

.contact-form button:hover {
  background-color: var(--spot-primary);
  color: black;
}

.contact-form .status {
  color: var(--text-secondary);
}

.contact-form .errorMsg {
  color: #b00000;
}
