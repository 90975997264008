.nav-logo {
  padding: 0px;
  font-size: 24px;
  display: flex;
  padding: 8px;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  color: #14213D;
  font-family: var(--font-primary);
  margin-right: auto;
  outline: none;
}

.nav-logo:hover {
  cursor: pointer; 
}

#logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  flex-wrap: wrap;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  margin: .25em .25em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.nav-link {
  font-size: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  border-bottom: 2px solid var(--spot-primary);
  margin: .25em .1em;
  background-color: var(--bg-secondary);
  cursor: pointer;
  color: var(--text-secondary);
  font-family: var(--font-primary);
  transition: .3s all;
}

.nav-link:hover {
  background-color: var(--spot-primary);
  color: black;
}