.showcase-box {
  --showcase-border: 2px solid gray;
  max-width: 375px;
  margin: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--showcase-primary);
  border: var(--showcase-border);
  padding: 1em;

}

.showcase-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0px;
}

.showcase-header {
  margin-top: .5em;
  margin-bottom: 1em;
}

.showcase-header h2 {
  text-align: center;
  margin: 0;
}

.showcase-roles {
  margin: .5em 0;
}

.showcase-role-title {
  font-weight: bold;
  font-size: 16px;
  margin-right: .5em;
}

.showcase-roles {
  text-align: center;
}

.showcase-description {
  margin: 1em;
  margin-top: 0;
}

.showcase-description p {
  margin: 0;
}

.showcase-image {
    width: calc(100%); 
    border-radius: 4px;
    margin-bottom: 1em;
}

.action-button {
  border: none;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border-bottom: 2px solid var(--spot-primary);
  border-radius: 4px;
  font-size: 16px;
  padding: .5em 1em;
  margin: 0 .5em;
  cursor: pointer;
  font-weight: bold;
  transition: .3s all;
}

.action-button:hover {
  background-color: var(--spot-primary);
  color: black;
}

.showcase-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}